import { useNotificationStore } from '@/stores/notification';
import { nextTick } from 'vue';
import { TYPE, useToast } from 'vue-toastification';

export function useNotification() {
    const notificationStore = useNotificationStore();
    const toast = useToast();

    async function show(message: string, sentAt: string, typeString: string | null) {
        const type = parseType(typeString);

        // Prevent duplicate messages
        if (sentAt === notificationStore.lastMessageSentAt) {
            return;
        }

        notificationStore.lastMessageSentAt = sentAt;

        await nextTick();

        toast(message, {
            type: type,
        });
    }

    function parseType(type: string | null) {
        switch (type) {
            case 'success':
                return TYPE.SUCCESS;
            case 'error':
                return TYPE.ERROR;
            case 'info':
                return TYPE.INFO;
            case 'warning':
                return TYPE.WARNING;
            default:
                return TYPE.DEFAULT;
        }
    }

    return {
        toast,
        show,
    };
}
